<template>
    <div class="hc22Box">
        <div class="hbBox1" v-if="getAdvData(20).children">
            <el-carousel indicator-position="none" height="760px">
                <template v-for="el in getAdvData(20).children">
                    <el-carousel-item v-if="el.image" :key="el.id">
                        <div class="hbBox1_item">
                            <a :href="el.url" target="_blank" rel="noopener noreferrer">
                                <img :src="el.image" />
                            </a>
                        </div>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
        <div class="hbBox2">
            <div class="hb2Top wow animate__animated animate__slideInUp">
                <p class="ht1">选择小程序 抢占10亿流量红利</p>
                <p class="ht2">共享10亿微信活跃用户，打开流量红利入口，解决了传统APP必须花钱买流量的痛点</p>
            </div>

            <div class="hb2List">
                <el-carousel :interval="5000" indicator-position="outside" height="464px" :autoplay="false">
                    <el-carousel-item>
                        <ul class="ul1">
                            <li class="hb2Li wow animate__animated animate__slideInUp" v-for="item in 8" :key="item">
                                <div class="hlBox">
                                    <div class="hb1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                    <p class="hb2">丰富的组件和API</p>
                                    <p class="hb3">界面、视图、内容、按钮、导航、位置多媒体、数据、网络、重力感应等</p>
                                </div>
                            </li>
                        </ul>
                    </el-carousel-item>
                    <el-carousel-item>
                        <ul class="ul2">
                            <li class="hb2Li wow animate__animated animate__slideInUp" v-for="item in 6" :key="item">
                                <div class="hlBox">
                                    <div class="hb1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                    <p class="hb2">丰富的组件和API</p>
                                    <p class="hb3">界面、视图、内容、按钮、导航、位置多媒体、数据、网络、重力感应等</p>
                                </div>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Top wow animate__animated animate__slideInUp">
                <p class="h3t1">小程序解决方案</p>
                <p class="h3t2">一站式小程序解决方案，满足各行业用户需求，助你无缝打通线上线下</p>
            </div>
            <div class="hb3List wow animate__animated animate__slideInUp">
                <div class="swiper-container thCenter swOne">
                    <ul class="tcUl swiper-wrapper">
                        <li class="tcLi swiper-slide" v-for="item in 8" :key="item">
                            <div class="hlBox">
                                <div class="hb1">
                                    <img src="https://iv.vu818.com/img/vu618.png" alt="" />
                                </div>
                                <div class="hb2">
                                    <div class="hb2t">
                                        <p class="h21">商场版</p>
                                        <p class="h22">
                                            集想要、支付、会员、财务、分销、卡卷等于一体的小程序商场，让商家轻松打通线上线下。
                                        </p>
                                    </div>
                                    <div class="hb3">
                                        <p class="h31">功能板块</p>
                                        <p class="h32">
                                            商品展示、想要支付、优惠卡卷<br />
                                            订单管理、会员管理、支持分销<br />
                                            支持拼团、财务管理、支持秒杀
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Top wow animate__animated animate__slideInUp">
                <p class="h4t1">小程序适用行业</p>
                <p class="h4t2">适用于各种需要为消费者提供优质用户体验的企业，量身定制，打造专属您的高端产品</p>
            </div>
            <div class="hb4List">
                <el-carousel :interval="5000" indicator-position="outside" height="410px">
                    <el-carousel-item>
                        <ul>
                            <li class="hb4Li wow animate__animated animate__slideInUp" v-for="item in 8" :key="item">
                                <div class="hl1">
                                    <img src="http://iv.vu818.com/img/hc2441.png" alt="" />
                                </div>
                                <p class="hl2">鲜花烘焙</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                    <el-carousel-item>
                        <ul>
                            <li class="hb4Li wow animate__animated animate__slideInUp" v-for="item in 6" :key="item">
                                <div class="hl1">
                                    <img src="http://iv.vu818.com/img/hc2441.png" alt="" />
                                </div>
                                <p class="hl2">鲜花烘焙</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Top wow animate__animated animate__slideInUp">
                <p class="h5t1">小程序流量入口</p>
                <p class="h5t2">众多流量入口，集展示、互动、交易为一体，抢占市场，抓住机遇</p>
            </div>
            <div class="hb5List">
                <el-carousel :interval="5000" indicator-position="outside" height="450px">
                    <el-carousel-item>
                        <ul class="ul1">
                            <li class="hb5Li wow animate__animated animate__slideInUp" v-for="item in 12" :key="item">
                                <div class="hl1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                <p class="hl2">二维码</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                    <el-carousel-item>
                        <ul class="ul2">
                            <li class="hb5Li wow animate__animated animate__slideInUp" v-for="item in 4" :key="item">
                                <div class="hl1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                <p class="hl2">二维码</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb6Top wow animate__animated animate__slideInUp">
                <p class="h6t1">小程序高端定制模块</p>
                <p class="h6t2">多模式服务个性化定制，丰富的营销功能，按需定制，助力企业打造智能商业系统</p>
            </div>
            <div class="hb6List">
                <el-carousel :interval="5000" indicator-position="outside" height="376px" :autoplay="false">
                    <el-carousel-item>
                        <ul class="ul1">
                            <li class="hb6Li wow animate__animated animate__slideInUp" v-for="item in 10" :key="item">
                                <div class="hl1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                <p class="hl2">拼单</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                    <el-carousel-item>
                        <ul class="ul2">
                            <li class="hb6Li wow animate__animated animate__slideInUp" v-for="item in 10" :key="item">
                                <div class="hl1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                <p class="hl2">拼单</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                    <el-carousel-item>
                        <ul class="ul3">
                            <li class="hb6Li wow animate__animated animate__slideInUp" v-for="item in 10" :key="item">
                                <div class="hl1"><img src="https://iv.vu818.com/img/vu618.png" alt="" /></div>
                                <p class="hl2">拼单</p>
                            </li>
                        </ul>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="hbBox7">
            <div class="hb7Top wow animate__animated animate__slideInUp">
                <p class="h7t1">客户案例</p>
                <p class="h7t2">一站式小程序开发定制服务，覆盖各个主流行业，快人一步打造企业专属小程序，抢占市场先机</p>
            </div>
            <div class="hb7List">
                <div class="thCenter swiper-container swTwo">
                    <ul class="tcUl2 swiper-wrapper wow animate__animated animate__slideInUp">
                        <li class="hb7Li swiper-slide" v-for="(item, i) in list" :key="item.id">
                            <img :src="item.little_image" :data-index="i" />
                            <div class="hb7Mask" v-if="item.qrcode || item.icon" :data-index="i">
                                <img v-if="item.qrcode" :src="item.qrcode" />
                                <img v-else :src="item.icon" />
                                <!-- <p class="hb72">{{ item.category_title }}</p> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hb7More" @click="goCase">
                更多案例
                <div class="adbImg">
                    <img class="aiImg" :src="getWebData().public_phone" alt="" />
                </div>
            </div>
        </div>
        <div class="hbBox8">
            <div class="hb8Top wow animate__animated animate__slideInUp">
                <p class="h8t1">小程序定制开发服务流程</p>
                <p class="h8t2">严谨的项目管理流程，确保项目进度和质量</p>
            </div>
            <div class="nb8List">
                <div class="toRight" style="width: 0; overflow: hidden; transition: width 2s">
                    <img src="http://iv.vu818.com/img/xcx_bg_line.png" alt="" class="nb8Bg" />
                </div>
                <ul>
                    <li class="nb8Li" v-for="item in 8" :key="item">
                        <div class="n8T">
                            <p class="n8t1">评估需求</p>
                            <p class="n8t2">需求收集、定位分析、可行性分析</p>
                        </div>
                        <div class="n8N">{{ item }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox9">
            <div class="hb9Top wow animate__animated animate__slideInUp">
                <p class="h9t1">为什么选择我们？</p>
                <p class="h9t2">
                    6年实体企业经营，专注小程序定制开发，拥有丰富开发经验和专业的技术团队，确保小程序如期上线，安全高效运营
                </p>
            </div>
            <div class="hb9Box">
                <div class="h9Left wow animate__animated animate__slideInLeft">
                    <div class="h9b" v-for="item in 3" :key="item">
                        <p class="hb1">6年经验 实力雄厚</p>
                        <p class="hb2">6年实体企业经营，专注小程序定制开发，开发经验丰富，专业可靠。</p>
                    </div>
                </div>
                <div class="h9Right wow animate__animated animate__slideInRight">
                    <div class="h9b" v-for="item in 3" :key="item">
                        <p class="hb1">稳定可靠 安全高效</p>
                        <p class="hb2">
                            6年技术功底，90+研发团队，代码架构开发规范、 设置QC环节对网站全方面进行安全检测；
                            多个海内外多线机房保证网站从代码到服务器安全稳定，打开速度快。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import axios from "axios";

export default {
    name: "HsContent22",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            list: [],
        };
    },
    async created() {
        await this.getData();
        this.$nextTick(() => {
            new Swiper(".swTwo", {
                autoplay: {
                    disableOnInteraction: false,
                }, //可选选项，自动滑动
                initialSlide: 0, //默认位置
                direction: "horizontal", //horizontal横向   vertical：竖向切换
                speed: 1000, //动画完成时间  autoplay : { delay:3000 },//切换一次动画时间
                // grabCursor: true, //鼠标移上变成小手
                // centeredSlides: true,
                slidesPerView: 6,
                spaceBetween: 20,
                loop: true, // 无限轮播
                on: {
                    click: e => {
                        let i = e.target.dataset.index;
                        this.goDetail(this.list[i]);
                        // console.log("xxx", e.target.dataset.index);
                    },
                },
            });
        });
    },
    mounted() {
        this.$nextTick(() => {
            new Swiper(".swOne", {
                // autoplay: true, //可选选项，自动滑动
                initialSlide: 0, //默认位置
                direction: "horizontal", //horizontal横向   vertical：竖向切换
                speed: 1000, //动画完成时间  autoplay : { delay:3000 },//切换一次动画时间
                grabCursor: true, //鼠标移上变成小手
                // centeredSlides: true,
                slidesPerView: 6,
                spaceBetween: 20,
                // loop: true, // 无限轮播
            });
        });
        function show() {
            let dom = document.getElementsByClassName("hbBox8");
            if (dom[0].getBoundingClientRect().top < 100) {
                document.getElementsByClassName("toRight")[0].style.width = "100%";
                window.removeEventListener("scroll", show);
            }
        }
        window.addEventListener("scroll", show);

        // this.$refs.test.setActive(true);
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        goCase() {
            this.$router.push("/case");
        },
        async getData() {
            const { data } = await axios.get("api/project/homeApplet");
            this.list = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
// .swiper-slide {
//     display: inline-block;
//     width: 200px !important;
//     height: 200px;
//     margin: 10px;
//     background: yellow;
// }
.test:hover {
    cursor: grab;
    filter: brightness(90%);
}
.test:active {
    cursor: grabbing;
}
.hc22Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        height: 800px;
        .hbBox1_item {
            width: 100%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                top: 0px;
                // left: 0px;
                // width: 100%;
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 838px;
        overflow: hidden;
        .hb2Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .ht1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .ht2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb2List {
            max-width: 1280px;
            height: 464px;
            margin: 0 auto;
            margin-top: 50px;
            .ul1 {
                li:nth-of-type(1) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -160px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hb1 {
                        img {
                            left: 0px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hb1 {
                        img {
                            left: -84px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -533px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -730px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -344px;
                        }
                    }
                }
                li:nth-of-type(7) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -282px;
                        }
                    }
                }
                li:nth-of-type(8) {
                    .hb1 {
                        width: 60px !important;

                        img {
                            left: -410px;
                        }
                    }
                }
            }
            .ul2 {
                li:nth-of-type(1) {
                    .hb1 {
                        img {
                            left: -587px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -473px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -220px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -667px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -851px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hb1 {
                        width: 60px !important;
                        img {
                            left: -790px;
                        }
                    }
                }
            }

            ul {
                width: 90%;
                height: 100%;
                margin: 0 auto;
                // display: flex;
                // justify-content: space-around;
                // flex-wrap: wrap;
                .hb2Li {
                    width: 25%;
                    height: 222px;
                    position: relative;
                    float: left;
                    .hlBox {
                        width: 90%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 3%;
                        text-align: center;
                        .hb1 {
                            width: 84px;
                            height: 84px;
                            margin: 0 auto;
                            overflow: hidden;
                            transition: 0.5s;
                            position: relative;
                            img {
                                position: absolute;
                                top: -140px;
                                // width: 100%;
                                // height: 100%;
                            }
                        }
                        .hb2 {
                            width: 100%;
                            height: 24px;
                            line-height: 24px;
                            margin-top: 10px;
                            font-size: 18px;
                        }
                        .hb3 {
                            width: 100%;
                            height: 95px;
                            margin-top: 10px;
                            font-size: 12px;
                        }
                    }
                }
                // .hb2Li::after {
                //   content: "";
                //   position: absolute;
                //   right: -1px;
                //   top: 72px;
                //   height: 140px;
                //   width: 1px;
                //   background: #ccc;
                // }
                .hb2Li:hover {
                    .hb1 {
                        transform: scale(1.3);
                    }
                }
                .hb2Li:nth-child(4),
                .hb2Li:nth-child(8) {
                    border: none;
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        // height: 745px;
        overflow: hidden;
        background: #222742;
        padding: 70px 0;
        .hb3Top {
            width: 100%;
            height: 98px;
            text-align: center;
            // margin-top: 70px;
            .h3t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                color: white;
                font-size: 48px;
            }
            .h3t2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                color: gray;
                margin-top: 10px;
            }
        }

        .hb3List {
            // max-width: 1340px;
            // width: 100vw;
            height: 359px;
            margin: 0 auto;
            margin-top: 70px;
            // 左边按钮
            .slLeftBtn {
                width: 5%;
                height: 80px;
                line-height: 100px;
                text-align: center;
                margin-top: 120px;
                float: left;
                cursor: pointer;
                transition: 1s;
                overflow: hidden;
                position: relative;
                img {
                    width: 1900px;
                    position: absolute;
                    top: -1015px;
                    left: -265px;
                }
                .iconfont {
                    font-size: 50px;
                }
            }
            .slLeftBtn:hover {
                .iconfont {
                    color: #7781f1;
                }
            }
            .slbs {
                opacity: 0;
                transition: 1s;
            }
            // 右边按钮
            .slRightBtn {
                width: 5%;
                height: 80px;
                line-height: 100px;
                text-align: center;
                float: left;
                margin-top: 120px;
                cursor: pointer;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -815px;
                    left: -262px;
                }
                .iconfont {
                    font-size: 50px;
                }
            }
            .slRightBtn:hover {
                .iconfont {
                    color: #7781f1;
                }
            }
            .thCenter {
                width: 100%;
                height: 100% !important;
                margin: 0 auto;
                .tcUl {
                    height: 100%;
                    transition: 0.5s;
                    & > li:nth-of-type(1) {
                        background-color: rgb(219, 198, 159);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -634px;
                            }
                        }
                    }
                    & > li:nth-of-type(2) {
                        background-color: rgb(248, 123, 119);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -261px;
                            }
                        }
                    }
                    & > li:nth-of-type(3) {
                        background-color: rgb(162, 115, 227);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -510px;
                            }
                        }
                    }
                    & > li:nth-of-type(4) {
                        background-color: rgb(254, 165, 159);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -5px;
                            }
                        }
                    }
                    & > li:nth-of-type(5) {
                        background-color: rgb(84, 112, 255);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -885px;
                            }
                        }
                    }
                    & > li:nth-of-type(6) {
                        background-color: rgb(230, 130, 255);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -136px;
                            }
                        }
                    }
                    & > li:nth-of-type(7) {
                        background-color: rgb(84, 112, 255);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -387px;
                            }
                        }
                    }
                    & > li:nth-of-type(8) {
                        background-color: rgb(130, 142, 255);
                        .hb1 {
                            img {
                                top: -527px;
                                left: -758px;
                            }
                        }
                    }
                    .tcLi {
                        height: 95%;
                        border-radius: 20px;
                        &:hover {
                            .hb1 {
                                animation: an 0.6s;
                            }
                            .hb2t {
                                opacity: 0 !important;
                                transform: translate(0, -40px);
                            }
                            .hb3 {
                                opacity: 1 !important;
                                transform: translate(0, -40px);
                            }
                        }
                        @keyframes an {
                            0% {
                                transform: scale(1);
                            }
                            25% {
                                transform: scale(1.3);
                            }
                            50% {
                                transform: scale(1);
                            }
                            75% {
                                transform: scale(1.3);
                            }
                            100% {
                                transform: scale(1);
                            }
                        }
                        .hlBox {
                            width: 90%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 8%;
                            text-align: center;
                            color: white;
                            .hb1 {
                                width: 115px;
                                height: 115px;
                                margin: 0 auto;
                                margin-top: 30px;
                                position: relative;
                                overflow: hidden;
                                img {
                                    position: absolute;
                                    transition: 0.3s;
                                }
                            }
                            .hb2 {
                                width: 100%;
                                height: 100px;
                                margin-top: 30px;
                                // overflow: hidden;
                                .hb2t {
                                    width: 100%;
                                    height: 100%;
                                    transition: 0.3s;
                                    opacity: 1;
                                    .h21 {
                                        width: 100%;
                                        height: 36px;
                                        font-size: 18px;
                                    }
                                    .h22 {
                                        width: 100%;
                                        height: 60px;
                                        font-size: 12px;
                                    }
                                }
                                .hb3 {
                                    width: 100%;
                                    height: 100%;
                                    transition: 0.3s;
                                    opacity: 0;
                                    margin-top: -60px;
                                    .h31 {
                                        width: 100%;
                                        height: 36px;
                                        font-size: 18px;
                                    }
                                    .h32 {
                                        width: 100%;
                                        height: 60px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 749px;
        overflow: hidden;
        .hb4Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h4t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h4t2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb4List {
            max-width: 1280px;
            height: 410px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 95%;
                height: 100%;
                margin: 0 auto;
                // display: flex;
                // justify-content: space-around;
                // flex-wrap: wrap;
                .hb4Li {
                    width: 300px;
                    height: 195px;
                    float: left;
                    .hl1 {
                        width: 236px;
                        height: 140px;
                        border-radius: 20px;
                        margin: 0 auto;
                        overflow: hidden;
                        img {
                            width: 236px;
                            height: 140px;
                            border-radius: 20px;
                            transition: 0.5s;
                        }
                    }

                    .hl2 {
                        width: 246px;
                        height: 44px;
                        margin: 0 auto;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
                .hb4Li:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 822px;
        overflow: hidden;
        background-color: #e9eef2;
        .hb5Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h5t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h5t2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb5List {
            max-width: 1280px;
            height: 492px;
            margin: 0 auto;
            margin-top: 50px;
            .ul1 {
                li:nth-of-type(1) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -341px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -226px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -801px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -572px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -688px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -456px;
                        }
                    }
                }
                li:nth-of-type(7) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -455px;
                        }
                    }
                }
                li:nth-of-type(8) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: 4px;
                        }
                    }
                }
                li:nth-of-type(9) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -340px;
                        }
                    }
                }
                li:nth-of-type(10) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -918px;
                        }
                    }
                }
                li:nth-of-type(11) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -113px;
                        }
                    }
                }
                li:nth-of-type(12) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: 4px;
                        }
                    }
                }
            }
            .ul2 {
                li:nth-of-type(1) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -226px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -570px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hl1 {
                        img {
                            top: -1125px;
                            left: -111px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hl1 {
                        img {
                            top: -1006px;
                            left: -685px;
                        }
                    }
                }
            }
            ul {
                width: 95%;
                height: 100%;
                margin: 0 auto;
                .hb5Li {
                    width: 200px;
                    height: 227px;
                    padding-top: 10px;
                    float: left;
                    .hl1 {
                        width: 115px;
                        height: 115px;
                        margin: 0 auto;
                        position: relative;
                        transition: 0.5s;
                        overflow: hidden;
                        img {
                            position: absolute;
                        }
                    }
                    .hl2 {
                        width: 100%;
                        height: 42px;
                        line-height: 42px;
                        margin-top: 20px;
                        text-align: center;
                    }
                }
                .hb5Li:hover {
                    .hl1 {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 700px;
        overflow: hidden;
        .hb6Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h6t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h6t2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb6List {
            max-width: 1280px;
            height: 376px;
            margin: 0 auto;
            margin-top: 50px;
            .ul1 {
                li:nth-of-type(1) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -521px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -715px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -448px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -86px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hl1 {
                        img {
                            top: -144px;
                            left: -910px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: 0px;
                        }
                    }
                }
                li:nth-of-type(7) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -763px;
                        }
                    }
                }
                li:nth-of-type(8) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -566px;
                        }
                    }
                }
                li:nth-of-type(9) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -414px;
                        }
                    }
                }
                li:nth-of-type(10) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -642px;
                        }
                    }
                }
            }
            .ul2 {
                li:nth-of-type(1) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -1002px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hl1 {
                        width: 85px !important;
                        img {
                            top: 4px;
                            left: -288px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -846px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -927px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -488px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: 0px;
                        }
                    }
                }
                li:nth-of-type(7) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -163px;
                        }
                    }
                }
                li:nth-of-type(8) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -785px;
                        }
                    }
                }
                li:nth-of-type(9) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -76px;
                        }
                    }
                }
                li:nth-of-type(10) {
                    .hl1 {
                        width: 70px;
                        img {
                            top: -72px;
                            left: -854px;
                        }
                    }
                }
            }
            .ul3 {
                li:nth-of-type(1) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -215px;
                        }
                    }
                }
                li:nth-of-type(2) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -145px;
                        }
                    }
                }
                li:nth-of-type(3) {
                    .hl1 {
                        width: 65px !important;
                        img {
                            top: -72px;
                            left: -353px;
                        }
                    }
                }
                li:nth-of-type(4) {
                    .hl1 {
                        width: 65px !important;
                        img {
                            top: -72px;
                            left: -300px;
                        }
                    }
                }
                li:nth-of-type(5) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -920px;
                        }
                    }
                }
                li:nth-of-type(6) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -600px;
                        }
                    }
                }
                li:nth-of-type(7) {
                    .hl1 {
                        img {
                            top: -72px;
                            left: -232px;
                        }
                    }
                }
                li:nth-of-type(8) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -370px;
                        }
                    }
                }
                li:nth-of-type(9) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -1003px;
                        }
                    }
                }
                li:nth-of-type(10) {
                    .hl1 {
                        img {
                            top: 4px;
                            left: -679px;
                        }
                    }
                }
            }
            ul {
                width: 95%;
                height: 100%;
                margin: 0 auto;
                .hb6Li {
                    width: 240px;
                    height: 188px;
                    float: left;
                    .hl1 {
                        width: 75px;
                        height: 75px;
                        margin: 0 auto;
                        transition: 0.5s;
                        overflow: hidden;
                        position: relative;
                        img {
                            position: absolute;
                            // top: 0px;
                            // left: 0px;
                        }
                    }
                    .hl2 {
                        width: 100%;
                        height: 83px;
                        margin-top: 20px;
                        text-align: center;
                    }
                }
                .hb6Li:hover {
                    .hl1 {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .hbBox7 {
        width: 100%;
        height: 750px;
        background-color: #e9eef2;
        overflow: hidden;
        .hb7Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h7t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h7t2 {
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb7List {
            max-width: 1340px;
            height: 395px;
            margin: 0 auto;
            margin-top: 50px;

            // 左边按钮
            .slLeftBtn {
                width: 5%;
                height: 80px;
                line-height: 100px;
                text-align: center;
                margin-top: 120px;
                float: left;
                cursor: pointer;
                transition: 1s;
                overflow: hidden;
                position: relative;
                img {
                    width: 1900px;
                    position: absolute;
                    top: -1015px;
                    left: -265px;
                }
                .iconfont {
                    font-size: 50px;
                }
            }
            .slLeftBtn:hover {
                .iconfont {
                    color: #7781f1;
                }
            }
            .slbs {
                opacity: 0;
                transition: 1s;
            }
            // 右边按钮
            .slRightBtn {
                width: 4%;
                height: 80px;
                line-height: 100px;
                text-align: center;
                float: left;
                margin-top: 120px;
                cursor: pointer;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -815px;
                    left: -262px;
                }
                .iconfont {
                    font-size: 50px;
                }
            }
            .slRightBtn:hover {
                .iconfont {
                    color: #7781f1;
                }
            }
            .thCenter {
                width: 1200px;
                margin-left: 70px;
                // width: 100%;
                height: 100%;
                // margin: 0 auto;
                float: left;
                overflow: hidden;
                .tcUl2 {
                    width: 2000px;
                    height: 100%;
                    // overflow: hidden;
                    // transition: 0.5s;
                    .hb7Li {
                        // width: 184px;
                        height: 391px;
                        border-radius: 20px;
                        overflow: hidden;
                        position: relative;
                        z-index: 2;
                        cursor: pointer;
                        // float: left;
                        // margin-left: 15px;
                        & > img {
                            width: 100%;
                            height: 327px;
                            border-radius: 20px;
                            object-fit: cover;
                        }
                        .hb7Mask {
                            // width: 184px;
                            width: 100%;
                            height: 327px;
                            border-radius: 20px;
                            position: absolute;
                            top: 0;
                            background-color: rgba(255, 255, 255, 0.95);
                            opacity: 0;
                            transition: 0.5s;
                            img {
                                width: 100px;
                                height: 100px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                // display: block;
                                // margin: 0 auto;
                                // margin-top: 100px;
                            }
                        }
                    }
                    .hb7Li:hover {
                        .hb7Mask {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .hb7More {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #0f5aff;
            transition: 0.5s;
            cursor: pointer;
            position: relative;
            z-index: 8;
            .adbImg {
                width: 100px;
                height: 100px;
                position: absolute;
                bottom: 60px;
                left: calc(50% - 50px);
                opacity: 0;
                transition: 0.5s;
                display: block;
                .aiImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .hb7More:hover {
            transform: translateY(-10px);
            .adbImg {
                opacity: 1;
            }
        }
    }
    .hbBox8 {
        width: 100%;
        height: 800px;
        overflow: hidden;
        background: url("http://iv.vu818.com/img/xcx_bg.jpg");
        .hb8Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h8t1 {
                color: white;
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h8t2 {
                color: gray;
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .nb8List {
            max-width: 1920px;
            height: 670px;
            margin: 0 auto;
            position: relative;
            margin-top: -70px;
            .nb8Bg {
                // position: absolute;
                // width: 135%;
            }
            ul {
                width: 100%;
                height: 100%;
                .nb8Li {
                    width: 190px;
                    height: 180px;
                    .n8T {
                        width: 190px;
                        height: 120px;
                        border-radius: 10px;
                        background-color: rgba(34, 122, 234, 0.5);
                        color: white;
                        position: relative;
                        .n8t1 {
                            width: 90%;
                            height: 36px;
                            font-size: 18px;
                            margin: 0 auto;
                            padding-top: 10px;
                        }
                        .n8t2 {
                            width: 90%;
                            min-height: 36px;
                            font-size: 13px;
                            margin: 0 auto;
                            margin-top: 5px;
                        }
                    }
                    .n8T::after {
                        content: "";
                        position: absolute;
                        border: 10px solid transparent;
                        border-top-color: rgba(34, 122, 234, 0.5);
                        bottom: -20px;
                        left: 85px;
                    }
                    .n8N {
                        width: 28px;
                        height: 28px;
                        font-size: 18px;
                        background-color: #fff;
                        color: #227aea;
                        line-height: 28px;
                        border-radius: 50%;
                        text-align: center;
                        margin-top: 15px;
                        margin-left: 80px;
                    }
                }
                .nb8Li:nth-child(1) {
                    position: absolute;
                    left: 150px;
                    top: 230px;
                }
                .nb8Li:nth-child(2) {
                    position: absolute;
                    left: 300px;
                    top: 420px;
                    .n8T::after {
                        bottom: auto;
                        top: -20px;
                        border-top-color: transparent;
                        border-bottom-color: rgba(34, 122, 234, 0.5);
                    }
                    .n8N {
                        margin-top: -160px;
                    }
                }
                .nb8Li:nth-child(3) {
                    position: absolute;
                    left: 400px;
                    top: 255px;
                }
                .nb8Li:nth-child(4) {
                    position: absolute;
                    left: 600px;
                    top: 430px;
                    .n8T::after {
                        bottom: auto;
                        top: -20px;
                        border-top-color: transparent;
                        border-bottom-color: rgba(34, 122, 234, 0.5);
                    }
                    .n8N {
                        margin-top: -160px;
                    }
                }
                .nb8Li:nth-child(5) {
                    position: absolute;
                    left: 700px;
                    top: 220px;
                }
                .nb8Li:nth-child(6) {
                    position: absolute;
                    left: 900px;
                    top: 300px;
                    .n8T::after {
                        bottom: auto;
                        top: -20px;
                        border-top-color: transparent;
                        border-bottom-color: rgba(34, 122, 234, 0.5);
                    }
                    .n8N {
                        margin-top: -160px;
                    }
                }
                .nb8Li:nth-child(7) {
                    position: absolute;
                    right: 250px;
                    top: 120px;
                }
                .nb8Li:nth-child(8) {
                    position: absolute;
                    right: 50px;
                    top: 260px;
                    .n8T::after {
                        bottom: auto;
                        top: -20px;
                        border-top-color: transparent;
                        border-bottom-color: rgba(34, 122, 234, 0.5);
                    }
                    .n8N {
                        margin-top: -160px;
                    }
                }
            }
        }
    }
    .hbBox9 {
        width: 100%;
        height: 630px;
        overflow: hidden;
        .hb9Top {
            width: 100%;
            height: 150px;
            margin-top: 50px;
            text-align: center;
            .h9t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 48px;
            }
            .h9t2 {
                color: gray;
                width: 100%;
                height: 24px;
                line-height: 24px;
                font-size: 18px;
                margin-top: 10px;
                color: gray;
            }
        }
        .hb9Box {
            max-width: 1120px;
            height: 410px;
            margin: 0 auto;
            background: url("http://iv.vu818.com/img/xcx_bg2.png") center no-repeat;
            .h9Left,
            .h9Right {
                width: 260px;
                height: 460px;
                float: left;
                display: flex;
                flex-direction: column;
                .h9b {
                    width: 260px;
                    height: 129px;
                    .hb1 {
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                        font-size: 18px;
                    }
                    .hb2 {
                        width: 100%;
                        height: 94px;
                        font-size: 12px;
                        color: gray;
                    }
                }
            }
            .h9Right {
                float: right;
            }
        }
    }
}
</style>
